import axios from "axios";
import { result } from "lodash";
import i18n from "../../i18n";
const fs = require("fs");
import Papa from "papaparse";
export default {
  state: {
    hearingInfo: [],
  },
  getters: {
    hearingInfo: (state) => state.hearingInfo,
    lastFetched: (state) => state.lastFetched,
    getHearingLocation: (state) => state.hearingLocation,
    intervalTimer: (state) => state.intervalTimer,
  },
  mutations: {
    setHearingInfo(state, hearingObj) {
      //console.log('Hearing Obj', hearingObj)
      //state.hearingInfo.push(hearingObj)
      state.hearingInfo = hearingObj;
    },
    emptyHearingInfo(state) {
      state.hearingInfo = [];
    },
  },
  actions: {
    fetchingHearingInfo({ commit }) {
      return new Promise((resolve, reject) => {
        let hearingLocation;
        let data = JSON.stringify({
          userName: "dekalb-ga",
          filePath: "/incoming",
          apiKey:
            "ars-bb-E4uOcpcLgEIKXd-pY5x9Z-bo!7Wl-mLuQCRTmvhqzi4kYpizFdV!tdJizI?Yb75HVSlpw74t-IodAkfSW3qNk7e2OZ8Vwa-ffojXZ2oWrP-AmV0y1z3?SJKmL",
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://us-central1-dekalb-ga-eeae7.cloudfunctions.net/apiV2/getHearingDetails/a763cd056f1b2405788443b7197e0708",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            Papa.parse(response.data, {
              header: true,
              skipEmptyLines: true,
              complete: (result) => {
                const data = result.data.map((row) => {
                  const caseNumber = row.LocationCode.slice(-8); // Extracting the part after 'SM3D'
                  let location = i18n
                    .t("maps")
                    .filter(
                      (map) =>
                        map.hearingLocationCode === row.LocationCode.slice(2, 4)
                    );
                  if (location.length === 1) {
                    hearingLocation = location[0].mapName;
                  } else {
                    if (row.LocationCode.slice(0, 2) === "SM") {
                      hearingLocation = "State Magistrate Court Clerk's Office";
                    } else {
                      hearingLocation = "Clerk's Office";
                    }
                  }
                  return {
                    partyName: row.Defendant.replace(/"/g, ""),
                    caseNumber: caseNumber,
                    hearingLocation: hearingLocation,
                    date: row.StartDate,
                    hearingTime: row.StartTime,
                  };
                });
                commit("setHearingInfo", data);
                resolve(true);
              },
            });
          })
          .catch((error) => {
            resolve(false);
            console.log(error);
          });
      });
    },
  },
};
