import { UniqueDirectiveNamesRule } from "graphql";
import * as fb from "@/firebase/index.js";
import router from "../../router";
import axios from "axios";
const crypto = require("crypto");
export default {
  state: {
    kioskProfile: {},
    kioskId: undefined,
    sessionId: null,
    count: 0,
    kioskModules: [],
    dataloadInit: false,
    qnaEndpointEN: {},
    qnaEndpointES: {},
  },
  getters: {
    getKioskId: (state) => state.kioskId,
    getSessionId: (state) => state.sessionId,
    getKioskProfile: (state) => state.kioskProfile,
    getQnaEndpointEN: (state) => state.qnaEndpointEN,
    getQnaEndpointES: (state) => state.qnaEndpointES,
    getCMSlink: (state) => state.kioskProfile.data().cmsLink,
    getKioskType: (state) => state.kioskProfile.data().email.split("@")[0],
    getKioskModules: (state) => state.kioskModules,
    getDataLoadInit: (state) => state.dataloadInit,
  },
  mutations: {
    setKioskId(state, kioskId) {
      state.kioskId = kioskId;
    },
    setSessionId(state, sessionId) {
      console.log("Session ID generated: ", sessionId);
      state.sessionId = sessionId;
    },
    setKioskProfile(state, profile) {
      state.kioskProfile = profile;
    },
    setKioskModules(state, module) {
      state.kioskModules.push(module);
    },
    setDataloadInit(state, status) {
      state.dataloadInit = status;
    },
    setQnaEndpointEN(state, qnaObj) {
      state.qnaEndpointEN.enable = qnaObj.enable;
      state.qnaEndpointEN.kbName = qnaObj.kbName;
    },
    setQnaEndpointES(state, qnaObj) {
      state.qnaEndpointES.enable = qnaObj.enable;
      state.qnaEndpointES.kbName = qnaObj.kbName;
    },
  },
  actions: {
    login({ dispatch }, loginObj) {
      return new Promise((resolve, reject) => {
        fb.auth
          .signInWithEmailAndPassword(loginObj.email, loginObj.password)
          .then((cred) => {
            dispatch("fetchKioskProfile", cred.user);
            resolve("Successfully LoggedIn");
            router.push("/loadData");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    fetchKioskProfile({ commit, dispatch, state, getters }, kiosk) {
      fb.kioskCollection.doc(kiosk.uid).onSnapshot((kioskData) => {
        if (kioskData.data() === undefined) {
          //dispatch("logout");
        } else {
          // Setting Kiosk ID
          commit("setKioskId", kiosk.uid);
          // Setting Kiosk Data
          commit("setKioskProfile", kioskData);

          // Checking refresh of the kiosk from Dahboard
          dispatch("autoRefreshKiosk", kioskData);

          commit("setAfterHours", kioskData.data().afterHours);
          if (!kioskData.data().afterHours) {
            router.push("/");
          }
          // if(this.getAfterHours !== kioskData.data().afterHours){
          //
          // }

          // To initiate data load from the data fetched from the firebase.
          if (!getters.getDataLoadInit) {
            //delete after testing
            commit("setDataloadInit", true);
            commit(
              "setSessionId",
              crypto.randomBytes(50).toString("hex").slice(0, 10)
            );
            dispatch("initiateDataPull", kioskData);
            // if (
            //   kioskData.data().avatarInfo.avatarId !==
            //     getters.getAvatarDetails.avatarId &&
            //   getters.getAvatarDetails !== null
            // ) {
            //   dispatch("applicationRefereshScheduler");
            // }
          } else {
            dispatch("identifyAvatarLangCodes", "en");
          }

          if (kioskData.data().enableScreenSaver) {
            commit("setScreenSaver", true);
            router.push("/screenSaver");
          }
        }
      });
    },
    updateKioskProfile({ commit, getters }) {
      fb.kioskCollection.doc(getters.getKioskId).update({
        enableScreenSaver: false,
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        fb.auth
          .signOut()
          .then(() => {
            commit("setKioskId", undefined);
            commit("setKioskProfile", {});
            commit("setDataloadInit", false);
            resolve("Successfully Logged Out");
            location.reload();
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    manageKioskPresence({ state }, kiosk) {
      var kioskStatusDatabaseRef = fb.firebase
        .database()
        .ref("/status/" + kiosk.uid);
      var isOfflineForDatabase = {
        state: "Unavailable",
        last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      var isOnlineForDatabase = {
        state: "Available",
        last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      fb.firebase
        .database()
        .ref(".info/connected")
        .on("value", function (snapshot) {
          // If we're not currently connected, don't do anything.
          if (snapshot.val() === false) return;

          kioskStatusDatabaseRef
            .onDisconnect()
            .set(isOfflineForDatabase)
            .then(function () {
              kioskStatusDatabaseRef.set(isOnlineForDatabase);
            });
        });
    },
    sendEmail({ state }, emailData) {
      return new Promise((resolve, reject) => {
        const mailObj = {
          to: emailData.address,
          message: {
            subject: emailData.subject,
            html: emailData.content,
          },
        };

        var config = {
          method: "post",
          url: "https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/mail/a763cd056f1b2405788443b7197e0708",
          headers: {
            "Content-Type": "application/json",
          },
          data: mailObj,
        };

        axios(config)
          .then(function (response) {
            resolve("Email sent successfully");
          })
          .catch(function (error) {
            reject(error.message);
          });

        fb.mailCollection
          .add(mailObj)
          .then(() => {
            resolve("Email sent successfully");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    sendQnaData({ state, commit }, data) {
      fb.qnaCollection
        .add(data)
        .then((response) => {
          //commit('setUserQnaRequest', response.id);
          console.log("QNA analytics added successfully");
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    // initiateDataPull({ commit, dispatch }, kioskData) {
    //   commit('setkioskLocation', kioskData.data().locationCode)
    //   dispatch('indentifyModuleLang', {
    //     module: kioskData.data().modules,
    //     language: kioskData.data().languages
    //   })
    //   //dispatch('updateAutoReferesh', kioskData)
    // },
    autoRefreshKiosk({ state }, kiosk) {
      fb.kioskCollection.doc(kiosk.id).onSnapshot((snapshot) => {
        if (snapshot.data().autoRefresh) {
          fb.kioskCollection
            .doc(kiosk.id)
            .update({
              autoRefresh: false,
            })
            .then(() => {
              location.reload();
            });
        }
      });
    },
    updateAutoReferesh({ state }, kiosk) {
      if (kiosk.data().autoRefresh) {
        //
      }
    },
  },
};
