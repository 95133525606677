module.exports = {
  firebase: {
    apiKey: "AIzaSyDbkctUE8NqpegpXA2ei9_wdUgQZ4PHzQE",
    authDomain: "dekalb-ga-eeae7.firebaseapp.com",
    databaseURL: "https://dekalb-ga-eeae7-default-rtdb.firebaseio.com",
    projectId: "dekalb-ga-eeae7",
    storageBucket: "dekalb-ga-eeae7.firebasestorage.app",
    messagingSenderId: "315037735521",
    appId: "1:315037735521:web:afd1da29d5718515302820"
  },
};
