<template>
  <v-row no-gutters class="mt-1">
    <v-col cols="3" align-self="center">
      <v-card height="785" flat class="containerBorder mx-3">
        <v-card-title class="d-flex justify-center font-weight-bold primary--text">
          {{ $t('header') }}
        </v-card-title>
        <v-divider class="mx-3"></v-divider>
        <v-card class="overflow-y-auto" height="675" flat>
          <v-list text>
            <v-list-item-group>
              <v-list-item v-for="(item, i) in maps" :key="i" color="primary"
                @click="selectImage(item.mapImage, item.mapFloor, item.speech, item.mapName);">
                <v-list-item-content>
                  <v-card min-height="45" @click="selectedMap = item.mapName"
                    class="pa-0 ma-0 text-wrap white--text text-left"
                    v-bind:color="item.mapName === selectedMap ? 'secondary' : 'primary'">
                    <v-card-title class="text-subtitle-1 font-weight-bold">
                      {{
                        item.mapName.toUpperCase() | trimLength
                      }}
                    </v-card-title>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-tabs background-color="primary" dark active-class="tabClass" v-model="active_tab" height="70" centered>
        <v-tab v-for="(floor, i) in floors" :key="i" align="center" class="text-subtitle-1 font-weight-bold pa-5"
          @click="
            selectImage(
              floor.floorImage.url,
              floor.floorNumber,
              floor.speech,
              floor.floorName
            );">
          {{ floor.floorName }}
        </v-tab>
      </v-tabs>
      <v-row no-gutters>
        <v-col align="center" justify="center" cols="12" align-self="center" class="pa-0">
          <v-img :src="url" width="83%" contain></v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "maps",
  data() {
    return {
      active_tab: 1,
      url: "",
      floorNum: null,
      showList: false,
      selectedMap: '',
      count: 0,
      showDepartments: null
    };
  },
  computed: {
    ...mapGetters(["getMaps", "getCMSlink", "getKioskProfile", "getSessionId"]),
    maps() {
      let temp = []
      this.$i18n.t('maps').forEach(map => {
        temp.push(map)
      })
      return temp.sort((a, b) => a.displayPosition - b.displayPosition)
    },
    floors() {
      return this.$i18n.t('floors').sort((a, b) => a.floorNumber - b.floorNumber)
    }
  },
  methods: {
    filterMaps(category) {
      let temp = []
      this.$i18n.t("maps").forEach(map => {
        if (map.category === category) {
          temp.push(map)
        }
      })
      return temp
    },
    selectImage(mapPath, mapFloor, speech, mapName) {
      this.active_tab = mapFloor;
      this.url = mapPath;
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      // Condition added to avoid redundant call from Mounted
      if (speech !== undefined) {
        this.$store.dispatch("avatarSpeak", speech);
        // Touch Usage
        const date = new Date()
        this.$store.dispatch('addUserSessionToStrapi', {
          applicationId: this.getKioskProfile.data().applicationId,
          actionType: 'Touch',
          applicationSessionId: this.getSessionId,
          timeStamp: date.toISOString(),
          sessionPayload: {
            module: "Maps and Directory",
            action: "Show Map",
            response: mapName,
            timeStamp: date.toISOString()
          }
        })
      }
    },
    showMaps(num) {
      if (this.floorNum === num) {
        this.showList = false;
        this.floorNum = 0;
      } else {
        this.floorNum = num;
        this.showList = true;
      }
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 40) {
        return val;
      }
      return `${val.substring(0, 35)}...`;
    },
  },
  mounted() {
    this.$i18n.t("floors").forEach(floor => {
      if (floor.defaultLocation) {
        this.selectImage(floor.floorImage.url, floor.floorNumber)
      }
    })
  },
};
</script>
<i18n>
{
  "en": {
      "header": "MAP DIRECTORY"
    },
  "es":{
      "header": "Directorio Mapas"
    }
}
</i18n>
<style scoped>
.containerBorder2 {
  border-radius: 0px !important;
}

.tabClass {
  background-color: #9F2B00 !important;
  color: white !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>