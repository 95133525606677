<template>
  <div>
    <v-row no-gutters class="mt-2" v-if="getAfterHours">
      <v-col cols="6">
        <avatar :initialWidth="avatarWidth"></avatar>
      </v-col>
      <v-col cols="6">
        <menuMatrix></menuMatrix>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2" v-else>
      <HearingMapInfo></HearingMapInfo>
    </v-row>
  </div>
</template>

<script>
import avatar from '@/components/avatar.vue'
import menuMatrix from '@/components/menuMatrix.vue'
import { mapGetters } from 'vuex';
import HearingMapInfo from '../components/hearingMapInfo.vue';

export default {
  name: 'Home',
  components: {
    avatar,
    menuMatrix,
    HearingMapInfo
  },
  computed: {
    ...mapGetters(['appDimensions', 'getAfterHours']),
    avatarWidth() {
      return this.appDimensions.width / 2
    }
  }
}
</script>
