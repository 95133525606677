<template>
  <v-app>
    <v-main>
      <v-layout column v-if="!getenableADA">
        <appHeader v-if="getKioskId !== undefined"></appHeader>
        <v-fade-transition leave-absolute>
          <router-view />
        </v-fade-transition>
      </v-layout>
      <v-layout column fill-height v-else>
        <v-fade-transition leave-absolute>
          <router-view />
        </v-fade-transition>
        <appHeader></appHeader>
      </v-layout>
    </v-main>
    <image-viewer></image-viewer>
    <email-viewer></email-viewer>
    <options-viewer></options-viewer>
    <session-end-viewer></session-end-viewer>
    <telepresence-viewer></telepresence-viewer>
    <recognition></recognition>
  </v-app>
</template>

<script>
import appHeader from "@/components/appHeader.vue";
import imageViewer from "@/components/dialogs/imageViewer";
import emailViewer from "@/components/dialogs/emailViewer";
import optionsViewer from "@/components/dialogs/optionsViewer";
import SessionEndViewer from "./components/dialogs/sessionEndViewer.vue";
import recognition from "@/components/recognition.vue";
import { mapGetters } from "vuex";
import TelepresenceViewer from './components/dialogs/telepresenceViewer.vue';
export default {
  name: "App",
  data() {
    return {
      count: 0,
      timeoutHours: 0
    };
  },
  components: {
    appHeader,
    recognition,
    imageViewer,
    emailViewer,
    optionsViewer,
    SessionEndViewer,
    TelepresenceViewer
  },
  computed: {
    ...mapGetters([
      "appDimensions",
      "getKioskId",
      "getStartUserSession",
      "getTouchCount",
      "isUserLooking",
      "getenableADA",
      "getAfterHours"
    ]),
  },
  created() {
    window.onclick = () => {
      if (this.getTouchCount >= 1 && !this.getAfterHours) {
        clearTimeout(this.timeoutHours)
        this.timeoutHours = setTimeout(() => { this.$store.dispatch("kioskStartOver") }, 30000)
      }
      if (this.getTouchCount === 1 && !this.isUserLooking && this.getAfterHours) {
        this.$store.dispatch("resetKiosk");
      } else if (this.getTouchCount !== 0) {
        this.$store.commit("setTouchCount", this.getTouchCount + 1);
      }
      if (this.getTouchCount > 1 && !this.isUserLooking && this.getAfterHours) {
        this.$store.dispatch("clearResetTimeout").then(() => {
          this.$store.dispatch("resetKiosk");
        });
      }
    };
  },
  mounted() {
    window["console"]["log"] = function () { };
    console.warn = function () { };
    console.error = function () { };
  }
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}

.v-list::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.v-list::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}

.v-virtual-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.v-virtual-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}

.v-dialog {
  margin-top: 100px;
}
</style>
