import axios from "axios";

const fs = require("fs");

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addQnaDatatoStrapi({ getters }, qnaData) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://us-central1-project-bluebox.cloudfunctions.net/action-logs/",
        data: qnaData,
      };
      axios
        .request(config)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    addUserSessionToStrapi({ getters }, sessionInfo) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://cms.dekalb.arsconnect.com/user-sessions/",
        data: sessionInfo,
      };
      console.log("Config: ", config);
      axios
        .request(config)
        .then((response) => {
          console.log("Session Added");
        })
        .catch((error) => {
          //console.log(error);
        });
    },
  },
};
