<template>
  <v-dialog v-model="getOptionsViewer.show" width="55%" persistent>
    <v-card class="pa-5">
      <v-row no-gutters>
        <v-col cols="1" align-self="center" v-if="getIsThinking">
          <v-img @click="actionOnTap()" class="talk-button" contain width="75"
            :src="require('../../assets/' + talkButtonSrc)">
          </v-img>
        </v-col>
        <v-col cols="1" align-self="center" v-else>
          <v-img @click="actionOnTap()" class="talk-button" contain width="75"
            :src="require('../../assets/' + talkButtonSrc)">
          </v-img>
        </v-col>
        <v-col cols="10" align-self="center" align="center">
          <span class="justify-center text-h5 font-weight-bold">
            {{ getOptionsViewer.dialogHeader }}
          </span>
        </v-col>
        <v-col cols="1" align-self="center" align="center">
          <v-btn icon @click="closeDialog()">
            <v-icon color="red" x-large>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-card-text align="center" class="pa-0 mt-2">
        <v-card v-for="(item, index) in getOptionsViewer.dialogData" :key="index" class="my-2" width="95%"
          @click="viewElement(item)" color="primary">
          <v-card-text class="white--text text-h6">
            <span v-if="typeof item === 'object'">
              {{ item }}
            </span>
            <span v-else>
              {{ item }}
            </span>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "optionsViewer",
  data() {
    return {
      talkButtonSrc: "talkButton.png",
    };
  },
  computed: {
    ...mapGetters([
      "getOptionsViewer",
      "getIsSpeaking",
      "speechRecognitionStatus",
      "getQnaStatus",
      "getResetKioskStatus",
      "getIsThinking",
      "getSessionId",
      "getKioskProfile"
    ]),
  },
  methods: {
    viewElement(input) {
      // Touch Usage

      const date = new Date()
      this.$store.dispatch('addUserSessionToStrapi', {
        applicationId: this.getKioskProfile.data().applicationId,
        actionType: 'Touch',
        applicationSessionId: this.getSessionId,
        timeStamp: date.toISOString(),
        sessionPayload: {
          module: "Options Viewer",
          action: input,
          response: 'Action Executed',
          timeStamp: date.toISOString()
        }
      })
      console.log('Input: ', input)
      this.$store.dispatch("requestQnA", input);
      this.$store.commit("closeOptionsViewer");
    },
    closeDialog() {
      this.$store.commit("closeOptionsViewer");
      this.$store.dispatch("kioskStillThere");
    },
    actionOnTap() {
      if (!this.getIsSpeaking) {
        if (this.getResetKioskStatus) {
          this.$store.commit("setSpeechRecognitionHandler", true);
        } else {
          //this.$store.commit('closeOptionsViewer')
          this.$store.commit("setSpeechRecognitionHandler", true);
        }
      } else {
        window.stopSpeech();
        this.talkButtonSrc = "talkButton.png";
      }
    },
  },
  watch: {
    getIsSpeaking(newVal) {
      if (newVal) {
        this.talkButtonSrc = "stopTalking.png";
      }
      if (!newVal && !this.speechRecognitionStatus) {
        this.talkButtonSrc = "talkButton.png";
      }
    },
    speechRecognitionStatus(newVal) {
      if (newVal) {
        this.talkButtonSrc = "talkButtonActive.webp";
      } else {
        this.talkButtonSrc = "talkButton.png";
      }
    },
  },
};
</script>

<style scoped>
.talk-button {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%) contrast(350%);
}
</style>
