import * as fb from "../../firebase/index.js";
import crypto from "crypto";
import axios from "axios";
import router from "@/router/index.js";
import { method } from "lodash";

export default {
  state: {
    callNotAnsweredTimeout: 0,
    callListener: null,
    meetingDetails: {},
    callDetails: null,
  },
  getters: {
    getCallListener: (state) => state.callListener,
    getMeetingDetails: (state) => state.meetingDetails,
    getCallDetails: (state) => state.callDetails,
  },
  mutations: {
    setMeetingDetails(state, meetingObj) {
      state.meetingDetails = meetingObj;
    },
    setCallDetails(state, obj) {
      state.callDetails = obj;
    },
  },
  actions: {
    initiateCall({ state, getters, dispatch }) {
      let date = new Date()
      if (!getters.getKioskProfile.data().telepresence) {
        dispatch(
          "avatarSpeak",
          "Telepresence not set up. Please contact the Clerk`s Office."
        );
      } else {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://us-central1-dekalb-628b8.cloudfunctions.net/apiV2/initiateCall/a763cd056f1b2405788443b7197e0708",
          data: {
            kioskDepartment: getters.getKioskProfile.data().kioskDepartment,
          },
          headers: {},
        };
        axios.request(config).then((response) => {
          console.log("Assigned Groups: ", response.data);
          if (response.data === null) {
            dispatch(
              "avatarSpeak",
              "Kiosk is not configured for telepresence. Please Contact the Administration office."
            );
          } else {
            // Group is assigned to the user
            let config1 = {
              method: "post",
              maxBodyLength: Infinity,
              url:
                "https://us-central1-dekalb-628b8.cloudfunctions.net/apiV2/checkUserAvailability/a763cd056f1b2405788443b7197e0708?groupId=" +
                response.data,
              headers: {},
            };
            axios.request(config1).then((response1) => {
              if (response1.data.length === 0) {
                dispatch(
                  "avatarSpeak",
                  "No users are available. Please try after sometime."
                );
              } else {
                console.log("Available Users: ", response1.data);
                let config2 = {
                  method: "post",
                  maxBodyLength: Infinity,
                  url: "https://us-central1-dekalb-628b8.cloudfunctions.net/apiV2/startCall/a763cd056f1b2405788443b7197e0708",
                  headers: {},
                  data: {
                    kioskInfo: {
                      kioskId: getters.getKioskId,
                      kioskName: getters.getKioskProfile.data().name,
                    },
                    userList: response1.data,
                    sessionId: "",
                  },
                };
                axios.request(config2).then((response2) => {

                  console.log("Call Started", response2.data);
                  dispatch("handleCall", response2.data);
                  state.callNotAnsweredTimeout = setTimeout(() => {
                    dispatch("callNotAnswered", response2.data);
                  }, 30000);
                });
              }
            });
          }
        });
      }
    },

    handleCall({ state, dispatch, commit, getters }, callId) {
      let date = new Date();
      state.callListener = fb.telepresenceCollection
        .doc(callId)
        .onSnapshot((call) => {
          switch (call.data().status) {
            case "Initiated":
              // Initiated
              dispatch(
                "avatarSpeak",
                "Hang On! Connecting you to a live person."
              );
              break;
            case "inProgress":
              // In Progress
              commit("setCallDetails", call);
              //dispatch('avatarSpeak', 'Video Call page coming up soon')
              clearTimeout(state.callNotAnsweredTimeout);
              router.push("/telepresence");
              break;
            case "Declined":
              // Declined
              dispatch(
                "avatarSpeak",
                "All requested users declined the call. Please try again after sometime."
              );
              dispatch("addUserSessionToStrapi", {
                applicationId: getters.getKioskProfile.data().applicationId,
                actionType: "Telepresence",
                applicationSessionId: getters.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: call.data(),
              });
              clearTimeout(state.callNotAnsweredTimeout);
              break;
            case "notAnswered":
              dispatch("addUserSessionToStrapi", {
                applicationId: getters.getKioskProfile.data().applicationId,
                actionType: "Telepresence",
                applicationSessionId: getters.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: call.data(),
              });
              break;
            case "Ended":
              if (call.data().requestedUser.length === 0) {
                console.log("Call Ended: ", call.data());

                dispatch("addUserSessionToStrapi", {
                  applicationId: getters.getKioskProfile.data().applicationId,
                  actionType: "Telepresence",
                  applicationSessionId: getters.getSessionId,
                  timeStamp: date.toISOString(),
                  sessionPayload: call.data(),
                });
              }
              // Ended
              break;
            default:
              console.log("Something went wrong");
          }
        });
    },

    callNotAnswered({ dispatch }, callDoc) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://us-central1-dekalb-628b8.cloudfunctions.net/apiV2/callNotAnswered/a763cd056f1b2405788443b7197e0708",
        headers: {},
        params: { docId: callDoc },
      };
      axios
        .request(config)
        .then((response) => {
          console.log("Response: ", response);
          dispatch(
            "avatarSpeak",
            "Call Not Answered. Please try again after sometime."
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },

    endVideoCall({ commit }, callDoc) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://us-central1-dekalb-628b8.cloudfunctions.net/apiV2/endCallSource/a763cd056f1b2405788443b7197e0708",
        headers: {},
        params: { docId: callDoc },
      };

      axios
        .request(config)
        .then((response) => {
          console.log("Response: ", response);
          this.$store.commit(
            "setTextBubbleText",
            "Please face towards the camera, tap on the microphone button, and ask a court-related question."
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
